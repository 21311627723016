import { Component, Input, OnInit } from '@angular/core';
import { CustomFieldFilterPageRoute, Filter, FilterDialogConfig, FilterDialogContext, FilterDialogState, FilterPageRoute, FilterSet, FilterSetData, FilterType, InnerFilterSet } from '../filters.interface';
import { SaveFilterModalComponent } from '../save-filter-modal/save-filter-modal.component';
import { FilterManagementService } from '../filter-services/filter-management.service';
import { CompanyDataService } from 'app/shared/services/company-data.service';
import { BudgetDataService } from 'app/dashboard/budget-data/budget-data.service';
import { filter, switchMap } from 'rxjs/operators';
import { UserManager } from 'app/user/services/user-manager.service';
import { UtilityService } from 'app/shared/services/utility.service';
import { AppRoutingService } from '@shared/services/app-routing.service';
import { MatDialog } from '@angular/material/dialog';
import { Subscription, combineLatest, of } from 'rxjs';
import { CustomFieldFiltersManagementService } from '../filter-services/custom-field-filter-management.service';
import { SelectedValue, SelectItem } from '@shared/types/select-groups.interface';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'filters-management',
  templateUrl: './filters-management.component.html',
  styleUrls: ['./filters-management.component.scss']
})
export class FiltersManagementComponent implements OnInit {
  @Input() disabled = false;

  outerFiltersApplied = false;
  owner: { id: number, name: string };
  activeFilters: FilterSet;
  budgetData: { id: number, name: string };
  filtersData: Filter[] = [];
  selectedFiltersCount: number = null;
  savedFilters: FilterSetData[];
  filtersApplied = false;
  companyUsers: {[id: string]: string} = {};
  subscriptions = [];
  errorHandler = { error: err => this.handleError(err) };
  isCEGMode = false;
  customFiltersData: Filter[] = [];
  private lastRoutePath: string | null = null;

  private readonly notSpecifiedOption: SelectItem = {
    title: 'Not specified',
    value: FilterManagementService.NOT_SPECIFIED_FILTER_VALUE,
    alwaysOnTop: true
  }
  
  private activeRouteForCustomFilters: string;
  private isCustomFieldFiltersSelected: boolean;
  private expenseCFFiltersSubscription: Subscription = null;
  private routeSubscription: Subscription = null;
  private budgetCompanyChangeSub: Subscription = null;
  private managePageCFFiltersSubscription: Subscription = null;
  private calendarPageCFFiltersSubscription: Subscription = null;
  private currentBudget: number = null;

  constructor(
    private readonly filterManagementService: FilterManagementService,
    private readonly companyData: CompanyDataService,
    private readonly budgetDataService: BudgetDataService,
    private readonly userManager: UserManager,
    private readonly utilityService: UtilityService,
    public readonly appRoutingService: AppRoutingService,
    private readonly dialog: MatDialog,
    private readonly customFieldFiltersManagementService: CustomFieldFiltersManagementService,
    private router: Router,
  )  { }

  ngOnInit() {
    this.subscriptions.push(
      this.filterManagementService.budgetFiltersList$.subscribe(
        filters => this.savedFilters = filters
      ),

      this.companyData.companyUsersList$.subscribe(
        users => this.companyUsers = users.reduce(
          (store, user) =>
            ({...store, [user.id]: user.user_profile_detail.name}), {}
        )
      ),

      this.filterManagementService.currentFilterSet$.subscribe(
        filters => {

          if(JSON.stringify(filters) !== JSON.stringify(this.activeFilters)){
          // Allows us to map custom field filters to the correct field name
          let selectedActiveFilterSet =  this.prepareActiveFilterSetWithCustomFieldFilters(filters);
          this.activeFilters = selectedActiveFilterSet;
          
          let matchingFilters = selectedActiveFilterSet;
          if( Object.keys(selectedActiveFilterSet).length > 0) {
            matchingFilters = this.setFiltersContainerData();
            
            // Applies filter if there is a Diff between the Selected Filters and Applied Filters  
            if(this.checkIfFilterSelectedAndAppliedAreDifferent(selectedActiveFilterSet, matchingFilters)) {
              // Do not clear the Active Filter Id Storage when applying the matching filters
              this.applyFilters(this.createFilterSet(matchingFilters), false);
            }
          }
        }
          this.updateFilterContainerStatus()
        }
      ),

      this.userManager.currentUser$
        .pipe(filter(user => user != null))
        .subscribe(user => this.owner = ({ id: user.id, name: `${user.first_name} ${user.last_name}` })),

      this.filterManagementService.externalFiltersSet$.subscribe(
        extFiltersOn => {
          if (extFiltersOn !== this.outerFiltersApplied) {
            this.outerFiltersApplied = extFiltersOn;
            this.updateFilterContainerStatus();
          }
        }
      ),

      combineLatest(
        this.filterManagementService.currentFiltersData$,
        this.budgetDataService.selectedBudget$
    ).subscribe(([filtersData, selectedBudget]) => {
      this.budgetData = selectedBudget;
      this.isCEGMode = selectedBudget?.new_campaigns_programs_structure;
      if (this.isCEGMode) {
        let index = filtersData.findIndex(obj => obj.fieldName === "campaignTypes");
        if (index !== -1) {
          let objectToMove = filtersData.splice(index, 1)[0];
          objectToMove.title = "Campaign/Expense Group Type";
          objectToMove.plural= "Campaign/Expense Group Types";
          filtersData.push(objectToMove);
        }
        this.filtersData = filtersData;
      }
      else {  
        this.filtersData = filtersData;
      }
    })
    
    );

    this.filtersApplied = this.filtersApplied || this.outerFiltersApplied;
    
      this.getCustomFieldFiltersOptionsBasedOnRoute(this.router.url);
  
      if(!this.routeSubscription) {
        this.routeSubscription = this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            )
            .subscribe((event: NavigationEnd) => {
 
            const currentRoutePath = this.extractRoutePath(event.urlAfterRedirects);
            
            if ( ((this.lastRoutePath !== currentRoutePath) && !currentRoutePath.includes('spending/expenses(drawer')) 
              && 
            !( [CustomFieldFilterPageRoute.Manage, CustomFieldFilterPageRoute.Calendar].includes(this.activeRouteForCustomFilters as CustomFieldFilterPageRoute) && currentRoutePath.includes('drawerStack')  )) {
              
              if(
                [this.lastRoutePath || '', currentRoutePath].every(val => val?.includes('spending'))
              ) {
                this.lastRoutePath = currentRoutePath;
                return;  
              }

              this.lastRoutePath = currentRoutePath;
              if(this.isCustomFieldFiltersSelected) { 
                this.clearPreviousCustomFieldFiltersSelectionFromStorage();
              }
              this.checkUsageOfCEGFilters();
              this.checkUsageOfCustomFieldFilters();
              this.getCustomFieldFiltersOptionsBasedOnRoute(event.urlAfterRedirects);
            }          
        })
      }


    if(!this.budgetCompanyChangeSub) {

      this.budgetCompanyChangeSub = 
      combineLatest(
        [
          this.companyData.selectedCompany$,
          this.budgetDataService.selectedBudget$
        ]
      ).subscribe(([company, budget]) => {
        if (company) {
          const currentBudgetChanged = this.currentBudget && this.currentBudget !== budget.id;
          this.currentBudget = budget.id;
    
          this.filterManagementService.handleDisplayGoBack$.subscribe((goBack) => {
            const timeframesLengthOneOrGoBack = this.activeFilters['timeframes']?.length === 1 || goBack;
    
            if (!currentBudgetChanged
              && (
                (this.activeFilters['campaigns']?.length === 1 && timeframesLengthOneOrGoBack) ||
                (this.activeFilters['goals']?.length === 1 && timeframesLengthOneOrGoBack) ||
                (this.activeFilters['segments']?.length === 1 && timeframesLengthOneOrGoBack) ||
                (this.activeFilters['expenseBuckets']?.length === 1 && timeframesLengthOneOrGoBack)
              )
              && this.activeRouteForCustomFilters === 'Expense') {
              // This means we have arrived to expense from Accordion view Drawer 
              this.clearPreviousCompanyCustomFieldFilters(true);
            } else {
              this.clearPreviousCompanyCustomFieldFilters();
            }
            this.getCustomFieldFiltersOptionsBasedOnRoute(this.router.url);
          });
        }
        });
    }  

  }

  private setFiltersContainerData() {
    
    const filtersDataReducer = (store: Record<string, any>, filter: Filter) => {
      const selectedOptionsIds = this.activeFilters[filter.fieldName] || [];
        return {
          ...store,
          [filter.fieldName]: selectedOptionsIds.map(optId => {
            let title;
            let groupTitle;
            for (const option of filter.availableItems) {
              if (option.value === optId) {
                title = option.title;
                groupTitle = option.groupTitle;
                break;
              }
              if (option.children?.length) {
                const child = option.children.find(el => el.value === optId);
                if (child) {
                  title = child.title;
                  groupTitle = child.groupTitle;
                  break;
                }
              }
            }
            return {
              title: title || optId?.toString(),
              groupTitle,
              value: optId
            }
          })
      }
    }

    const pickedFilters = this.filtersData?.reduce(filtersDataReducer, {});

    this.customFiltersData = this.customFieldFiltersManagementService.getCustomFieldFiltersDataBasedOnActiveRoute(this.activeRouteForCustomFilters);

    const pickedCustomFilters = this.customFiltersData?.reduce(filtersDataReducer, {});

    const allPickedFilters = { ...pickedFilters, ...pickedCustomFilters}

    const displayedPickedFiltersInContainer = this.setDisplayedPickedFiltersSetInContainer(allPickedFilters);
    
    return displayedPickedFiltersInContainer;

  }

  // Only shows those Filters which are present and Selected Filter Id matches with the Available Items Filter Id
  public setDisplayedPickedFiltersSetInContainer(pickedFilters) {

    let allFiltersData = [...this.filtersData, ...this.customFiltersData];
    let displayedPickedFiltersInContainer = {};  

    for (let item in pickedFilters) {     
      displayedPickedFiltersInContainer[item] = [] 
      
      const pickedFilterValues = pickedFilters[item].map(it => it.value);
      let filter = allFiltersData.find(fd => fd.fieldName === item)
      // Handle the hierachy of the available items for filter controls if present
      const filterAvailableItemsValues = filter?.availableItems.flatMap(it => {
        if(it?.children) {
          return [ it.value, ...it.children.map(child => child.value)]
        }
        return it.value
      }) || []
      
      for (let value of pickedFilterValues) {
        if(filterAvailableItemsValues.includes(value)) {
          const pickedFilterItem = pickedFilters[item].find(it => it.value === value);
          displayedPickedFiltersInContainer[filter.fieldName] = [...displayedPickedFiltersInContainer[filter.fieldName], pickedFilterItem]
        }
      }
    }

   return displayedPickedFiltersInContainer;
  }
  

  public checkIfFilterSelectedAndAppliedAreDifferent(activeFilters: FilterSet, innerFilterSet: InnerFilterSet ) {
    
    const newAppliedFilterSet = {...innerFilterSet};
    const newActiveFilters = {...activeFilters};

    
    for(let filter in innerFilterSet) {
      newAppliedFilterSet[filter] = innerFilterSet[filter].map(item => item.value);
      newActiveFilters[filter] = activeFilters[filter]?.length ? activeFilters[filter] : [];
    }
    return JSON.stringify(newActiveFilters) !== JSON.stringify(newAppliedFilterSet);
  }

  
  public createFilterSet(pickedFilters: InnerFilterSet): FilterSet {
    return Object.entries(pickedFilters).reduce(
      (store, [fieldName, options]) => {
        store[fieldName] = options.map(opt => opt.value)
        return store;
      }, {}
    )
  }


  private getCustomFieldFiltersOptionsBasedOnRoute(url: string) {

    let routePath = this.extractRoutePath(url);
      if(routePath.includes(FilterPageRoute.Expenses) || routePath.includes(FilterPageRoute.Invoice)) {
        if(this.activeRouteForCustomFilters !== 'Expense' && this.activeRouteForCustomFilters !== 'Invoice' ) {
          this.clearPreviousCustomFieldFiltersSelectionFromStorage();
        }

      this.activeRouteForCustomFilters = 'Expense';
      if(routePath.includes(FilterPageRoute.Invoice)) {
        this.activeRouteForCustomFilters = 'Invoice';
      }

    }else if(routePath.includes(FilterPageRoute.Manage)){
      
      if(this.activeRouteForCustomFilters !== 'Manage') {
        this.clearPreviousCustomFieldFiltersSelectionFromStorage();
      }
      this.activeRouteForCustomFilters = 'Manage';
    
    } else if(routePath.includes(FilterPageRoute.Calendar)) {

      if(this.activeRouteForCustomFilters !== 'Calendar') {
      this.clearPreviousCustomFieldFiltersSelectionFromStorage();
      }
      this.activeRouteForCustomFilters = 'Calendar';
    }
    else{
      
      this.clearPreviousCustomFieldFiltersSelectionFromStorage();
      this.customFiltersData = [];
      this.activeRouteForCustomFilters = '';
    }

    this.enableCustomFieldFiltersSubscriptionForExpensePage();
    this.enableCustomFieldFiltersSubscriptionsForManagePage();
    this.enableCustomFieldFiltersSubscriptionsForCalendarPage();
  }


  private enableCustomFieldFiltersSubscriptionForExpensePage(){
    
    if(!this.expenseCFFiltersSubscription) {
      this.expenseCFFiltersSubscription =  this.customFieldFiltersManagementService.expenseCF$.subscribe((fields) => {
        console.log(fields);

        if(this.activeRouteForCustomFilters === 'Expense' || this.activeRouteForCustomFilters === 'Invoice') {
          this.customFiltersData = fields.map(field => ({
            fieldName:  field.cfName,
            plural: field.cfName,
            title: field.cfName,
            type: FilterType.CustomFieldFilter,
            availableItems: Object.values(field.options).map((ov: string)  => ({ title: ov, value: field.optionValueIdMapping[ov].id })).concat({ ...this.notSpecifiedOption, title: `No ${field.cfName}` }),
            cf_id: field.id
              
          })) as Filter[];


        this.checkUsageOfCEGFilters();
        this.checkUsageOfCustomFieldFilters();
        }
      });
    }
  }

  private enableCustomFieldFiltersSubscriptionsForManagePage(){

    if(!this.managePageCFFiltersSubscription) {
      this.managePageCFFiltersSubscription =  this.customFieldFiltersManagementService.managePageCF$.subscribe((fields) => {
        console.log("Mange Page CF Emitted : ", fields);

        if(this.activeRouteForCustomFilters === 'Manage') {
          this.customFiltersData = fields.map(field => ({
            fieldName:  field.cfName,
            plural: field.cfName,
            title: field.cfName,
            type: FilterType.CustomFieldFilter,
            availableItems: Object.values(field.optionValueIdMapping).map((ov: {option_value: string, id: number | string })  => ({ title: ov.option_value, value: ov.id })).concat({ ...this.notSpecifiedOption , title: `No ${field.cfName}`}),
            cf_id: field.id  
          })) as Filter[];


        this.checkUsageOfCEGFilters();
        this.checkUsageOfCustomFieldFilters();
        }
      });
    }

  }

  private enableCustomFieldFiltersSubscriptionsForCalendarPage(){

    if(!this.calendarPageCFFiltersSubscription) {
      this.calendarPageCFFiltersSubscription =  this.customFieldFiltersManagementService.calendarPageCF$.subscribe((fields) => {
        console.log("Calendar Page CF Emitted : ", fields);
 
        if(this.activeRouteForCustomFilters === 'Calendar' ) {
          this.customFiltersData = fields.map(field => ({
            fieldName:  field.cfName,
            plural: field.cfName,
            title: field.cfName,
            type: FilterType.CustomFieldFilter,
            availableItems: Object.values(field.optionValueIdMapping).map((ov: {option_value: string, id: number | string })  => ({ title: ov.option_value, value: ov.id })).concat({ ...this.notSpecifiedOption, title: `No ${field.cfName}` }),
            cf_id: field.id  
          })) as Filter[];


        this.checkUsageOfCEGFilters();
        this.checkUsageOfCustomFieldFilters();
        }
      });
    }

  }



  private clearPreviousCustomFieldFiltersSelectionFromStorage() {
      this.clearCustomFieldFiltersImmediately(this.activeFilters);
      
      // Clearing the custom field filters from the storage on logout
      if(this.router.url === '/login') {
        this.filterManagementService.filterSetStorage.remove(this.owner.id, this.budgetData?.id );
        this.activeFilters = {};
        this.filterManagementService.updateCurrentFilterSet(this.activeFilters);
        return;
      }
      if(this.router.url !== '/login' && this.owner?.id && this.budgetData?.id) {
        this.filterManagementService.filterSetStorage.save(this.owner.id, this.budgetData.id, this.activeFilters);
      }
  }

  private checkUsageOfCustomFieldFilters() {
    const isCustomFieldFiltersSelected = Object.keys(this.activeFilters).some(fieldName => {
      return this.customFiltersData.map(fd => fd.fieldName).includes(fieldName) && this.activeFilters[fieldName].length
    })

    this.isCustomFieldFiltersSelected = isCustomFieldFiltersSelected;

    this.customFieldFiltersManagementService.isCustomFieldFiltersSelected.next(isCustomFieldFiltersSelected);
  }

  private checkUsageOfCEGFilters() {
    const pickedCEGFilterList =  Object.keys(this.activeFilters).filter(item => !this.customFiltersData.map(fd => fd.fieldName).includes(item))
    const isCEGFiltersSelected = pickedCEGFilterList.some(fieldName => this.activeFilters[fieldName].length);
   
    this.customFieldFiltersManagementService.isCEGFiltersSelected.next(isCEGFiltersSelected);
  }


  private extractRoutePath(url: string): string {
    return url.split('?')[0].split('#')[0]; // Remove query parameters and fragments
  }


  private clearPreviousCompanyCustomFieldFilters(isAccordionNavigate = false) {
    this.clearCustomFieldFiltersImmediately(null, isAccordionNavigate);
    this.customFiltersData = [];
  }

  protected get isDrawerOpen(): boolean {
    return this.appRoutingService.isDrawerOpen();
  }

  protected closeDrawer(): void {
    this.appRoutingService.closeActiveDrawer();
  }

  onCloseFilterContainer() {
    this.hideContainer();
    this.updateSelectedFiltersCount();
  }

  hideContainer() {
    this.filterManagementService.toggleFilterVisibility(false);
  }

  removeFilterSet(id: number) {
    const budgetData = this.budgetData || this.budgetDataService.selectedBudgetSnapshot;
    return this.filterManagementService.deleteFilterSetData(
      id,
      budgetData.id,
      this.errorHandler.error);
  }

  updateFilterSet(filterData: FilterSetData) {
    const budgetData = this.budgetData || this.budgetDataService.selectedBudgetSnapshot;
    return this.filterManagementService.updateFilterSetData(
      filterData,
      budgetData.id,
      this.errorHandler.error);
  }

  applyFilters(filtersSet: FilterSet, clearActiveIdStorage = true) {
    this.filterManagementService.updateCurrentFilterSet(filtersSet, null, clearActiveIdStorage);
    this.hideContainer();
    this.updateSelectedFiltersCount();
  }

  selectActiveFilterSet(selectedFilterId: number) {
    const filterSetData = this.savedFilters.find(filter => filter.id === selectedFilterId);
    if (filterSetData && this.filterManagementService.activeFilterSetId !== selectedFilterId) {
      let selectedFilterSet: FilterSet = this.prepareActiveFilterSetWithCustomFieldFilters({ ...filterSetData.selectedFilters });
      this.filterManagementService.selectActiveFilterSet(selectedFilterId, selectedFilterSet);
    }
  }

  saveFilterSet(filterData: FilterSetData, filtersSet: FilterSet) {
    const budgetData = this.budgetData || this.budgetDataService.selectedBudgetSnapshot;

    let filterSetToBeSaved: FilterSet = {  };
    let customFieldFiltersName =  this.customFiltersData.map(fd => fd.fieldName)
    for(let k in filtersSet) {
     
      if(customFieldFiltersName.includes(k)) {
        let customFilterControl = this.customFiltersData.find(fd => fd.fieldName === k);
        let cf_id = customFilterControl['cf_id'];
        if('customFieldFilters' in filterSetToBeSaved) {
          filterSetToBeSaved['customFieldFilters'][cf_id] = filtersSet[k] as any;
        }else {
          filterSetToBeSaved['customFieldFilters'] = { [cf_id]:  filtersSet[k] } as any;
        }
      } else {
        filterSetToBeSaved[k] = filtersSet[k];
      }
    }

    return this.filterManagementService.saveFilterSetData(
      { ...filterData, selectedFilters: filterSetToBeSaved },
      budgetData.id,
      this.errorHandler.error
    );
  }


  prepareActiveFilterSetWithCustomFieldFilters(selectedFilters: FilterSet) {
      let cfFieldNameandIdMap = {}
      this.customFiltersData.forEach(data => {
        cfFieldNameandIdMap[data['cf_id']] = data.fieldName
      });

      let selectedFilterSet = { ...selectedFilters };
      
      if(Object.keys(selectedFilterSet?.customFieldFilters || []).length > 0) {
        for(let k in selectedFilterSet.customFieldFilters) {
          if(k in cfFieldNameandIdMap) {
            selectedFilterSet[cfFieldNameandIdMap[k]] = selectedFilterSet.customFieldFilters[k] as any as SelectedValue[];
            
          }
        }
      }
      
      delete selectedFilterSet.customFieldFilters;

      return selectedFilterSet;
}

  updateFilterContainerStatus() {
    const filtersApplied = Object.keys(this.activeFilters).some(
      fieldName => this.activeFilters[fieldName].length > 0
    );
    this.updateSelectedFiltersCount();
    this.filtersApplied = filtersApplied || this.outerFiltersApplied;
  }

  calcSelectedFilters() {
    if (this.activeFilters == null || typeof this.activeFilters !== 'object') {
      return 0;
    }

    return Object.keys(this.activeFilters).reduce((count, fieldName) => (
      this.activeFilters[fieldName]
        ? count + this.activeFilters[fieldName].length
        : count
    ), 0);
  }

  setSelectedFiltersCount(count) {
    this.selectedFiltersCount = count;
  }

  updateSelectedFiltersCount() {
    const totalCount = this.calcSelectedFilters();
    this.setSelectedFiltersCount(totalCount);
  }

  clearFiltersImmediately() {
    this.closeDrawer();
    this.filterManagementService.handleDisplayGoBack.next(false); // Close the Go Back Toastr
    const emptyFiltersSet: FilterSet = {};
    this.filtersData.forEach(filter => {
      emptyFiltersSet[filter.fieldName] = [];
    });
    this.clearCustomFieldFiltersImmediately(emptyFiltersSet);
  }

  clearCustomFieldFiltersImmediately(filterSet?: FilterSet, isAccordionNavigate = false) {
    const emptyFiltersSet: FilterSet = filterSet  || {};  
    (this.customFiltersData || []).forEach(filter => {
      emptyFiltersSet[filter.fieldName] = [];
    }); 

    if(!isAccordionNavigate) {
      this.applyFilters(emptyFiltersSet);
    }
    this.clearCustomFieldFiltersSelectionState();
    this.filterManagementService.notifyFiltersCleared();
  }

  clearCustomFieldFiltersSelectionState() {
    this.customFieldFiltersManagementService.isCustomFieldFiltersSelected.next(false);
    this.customFieldFiltersManagementService.isCEGFiltersSelected.next(false);
  }

  openSaveFilterModal(filterSet: FilterSet) {
    this.hideContainer();
    const dialogContext: FilterDialogContext = FilterDialogConfig[FilterDialogState.Save];

    dialogContext.context = {
      budgetData: this.budgetData || this.budgetDataService.selectedBudgetSnapshot,
      owner: this.owner,
      selectedFilterId: this.filterManagementService.activeFilterSetId,
      favouriteFilter: this.savedFilters.find(item => item.isFavourite)
    };
    dialogContext.state = FilterDialogState.Save;
    dialogContext.submitAction.handler = (
      details: FilterSetData,
      unfavouriteId?: number) => {
      const updateFavourite$ = unfavouriteId
        ? this.filterManagementService.resetFavouriteStatus(unfavouriteId, details.budgetId)
        : of([]);
      updateFavourite$.pipe(
        switchMap(() => this.saveFilterSet(details, filterSet))
      ).subscribe(this.errorHandler);
    }

    this.dialog.open(SaveFilterModalComponent, {
      width: dialogContext.width,
      data: dialogContext,
      autoFocus: false,
      panelClass: 'filter-dialog'
    });
  }

  openSavedFiltersModal() {
    this.closeDrawer();
    const dialogContext: FilterDialogContext = FilterDialogConfig[FilterDialogState.Select];

    dialogContext.context = {
      onSelectActive: (selectedFilterId) => this.selectActiveFilterSet(selectedFilterId),
      onRemove: (id) => { this.removeFilterSet(id).subscribe(this.errorHandler) },
      onUpdateFilterSet: (filterData: FilterSetData, unfavouriteId?: number) => {
        const onUpdate$ = this.updateFilterSet(filterData);
        const updateFavourite$ = unfavouriteId
          ? this.filterManagementService.resetFavouriteStatus(unfavouriteId, filterData.budgetId)
          : of([]);
        updateFavourite$.pipe(
          switchMap(() => onUpdate$)
        ).subscribe(this.errorHandler);
      },
      budgetData: this.budgetData || this.budgetDataService.selectedBudgetSnapshot,
      owner: this.owner,
      savedFilters : this.savedFilters,
      selectedFilterId: this.filterManagementService.activeFilterSetId,
      companyUsers: this.companyUsers,
      favouriteFilter: this.savedFilters.find(item => item.isFavourite)
    };

    this.dialog.open(SaveFilterModalComponent, {
      width: dialogContext.width,
      data: dialogContext,
      autoFocus: false,
      panelClass: 'filter-dialog'
    });
  }

  handleError(error, message = 'Request has failed') {
    console.error(message + '. ' + JSON.stringify(error));
    if (error && !error.message) {
      error.message = message;
    }
    this.utilityService.handleError(error);
  }

  ngOnDestroy() {
    this.expenseCFFiltersSubscription?.unsubscribe();
    this.managePageCFFiltersSubscription?.unsubscribe();
    this.calendarPageCFFiltersSubscription?.unsubscribe();
    this.budgetCompanyChangeSub?.unsubscribe();
  }
}
