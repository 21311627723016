import { Injectable, OnDestroy, inject } from '@angular/core';
import { FilterService } from './filter.service';
import { BehaviorSubject, combineLatest, forkJoin, merge, Observable, Subject } from 'rxjs';
import {
  Filter,
  FilterName,
  FilterSourceObject,
  FilterSet,
  FilterSetData,
  FilterType,
} from 'app/header-navigation/components/filters/filters.interface';
import { BudgetDataService } from 'app/dashboard/budget-data/budget-data.service';
import { CompanyDataService } from 'app/shared/services/company-data.service';
import { Configuration } from 'app/app.constants';
import { UtilityService } from 'app/shared/services/utility.service';
import { Budget } from 'app/shared/types/budget.interface';
import { first, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { BudgetSegmentAccess } from 'app/shared/types/segment.interface';
import { UserManager } from 'app/user/services/user-manager.service';
import { ObjectAccessManagerService } from 'app/shared/services/object-access-manager.service';
import { LightObject } from 'app/shared/types/segmented-budget-object';
import { SegmentGroup } from 'app/shared/types/segment-group.interface';
import { Campaign, LightCampaign } from 'app/shared/types/campaign.interface';
import { SelectedValue, SelectItem } from 'app/shared/types/select-groups.interface';
import { ProductDO } from '@shared/services/backend/product.service';
import { getMetricSelectItems } from 'app/budget-object-details/components/details-metrics/metric-masters-list/metric-masters-list.component';
import { BudgetObjectSourceLabels } from 'app/budget-object-details/types/budget-object-details-state.interface';
import { expenseSources } from '@shared/types/expense.interface';
import { CEGStatus, CEGStatusValue } from '@shared/enums/ceg-status.enum';
import { CustomFieldFiltersManagementService } from './custom-field-filter-management.service';

export interface ParamsDef {
  [paramName: string]: {
    filterName?: FilterName;
    defaultValue?: () => any;
  }
}

export enum CustomFilterModeType {
  RelatedExpenses = 'RelatedExpenses'
}

export interface CustomFilterMode {
  modeType: CustomFilterModeType;
  context?: Record<string, any>;
}

@Injectable()
export class FilterManagementService implements OnDestroy {
  public static NOT_SPECIFIED_FILTER_VALUE = 0;
  private readonly destroy$ = new Subject<void>();
  private readonly userIdPlaceholder = '{userId}';
  private readonly budgetIdPlaceholder = '{userId}';
  private readonly filterSetLSKey = `filterSet_${this.userIdPlaceholder}_${this.budgetIdPlaceholder}`;
  private readonly activeFilterSetLSKey = 'activeFilterSet';

  private readonly defaultFilterSet: FilterSet = {};
  private readonly allowNotSpecifiedOption = [
    FilterName.Tags, FilterName.GlCodes, FilterName.Vendors, FilterName.Campaigns, FilterName.ExpenseBuckets,
    FilterName.PONumber
  ]
  private  notSpecifiedOption: SelectItem = {
    title: 'Not specified',
    value: FilterManagementService.NOT_SPECIFIED_FILTER_VALUE,
    alwaysOnTop: true
  }

  private readonly filtersDataSnapshot: Filter[] = [
    { fieldName: FilterName.Timeframes, title: 'Timeframe', plural: 'timeframes', hideSearch: true, availableItems: [] },
    { fieldName: FilterName.Segments, title: 'Segment', plural: 'segments', availableItems: [], skipGroupsSelection: true },
    { fieldName: FilterName.Goals, title: 'Goal', plural: 'goals', availableItems: [] },
    { fieldName: FilterName.Campaigns, title: 'Campaign', plural: 'campaigns', availableItems: [] },
    { fieldName: FilterName.CampaignTypes, title: 'Campaign Type', plural: 'campaign types', availableItems: [] },
    { fieldName: FilterName.Statuses, title: 'Expense Status', plural: 'expense statuses', availableItems: [] },
    { fieldName: FilterName.ExpenseBuckets, title: 'Expense Group', plural: 'expense groups', availableItems: [] },
    { fieldName: FilterName.ExpenseTypes, title: 'Expense Type', plural: 'expense types', availableItems: [] },
    { fieldName: FilterName.ExpenseSource, title: 'Expense Source', plural: 'expense sources', availableItems: [] },
    { fieldName: FilterName.GlCodes, title: 'GL Code', plural: 'gl codes', availableItems: [] },
    { fieldName: FilterName.Vendors, title: 'Vendor', plural: 'vendors', availableItems: [] },
    { fieldName: FilterName.Owners, title: 'Owner', plural: 'owners', availableItems: [] },
    { fieldName: FilterName.Tags, title: 'Tag', plural: 'tags', availableItems: [] },
    { fieldName: FilterName.SharedCostRules, title: 'Shared Cost Rule', plural: 'shared cost rules', availableItems: [] },
    { fieldName: FilterName.Metrics, title: 'Metric', plural: 'Metrics', availableItems: [], skipGroupsSelection: true },
    { fieldName: FilterName.PONumber, title: 'PO Number', plural: 'PO Numbers', availableItems: [] },
    { fieldName: FilterName.CEGStatus, title: 'Status', plural: 'Statuses', availableItems: [] },
  ];

  private readonly budgetFiltersList = new Subject<FilterSetData[]>();
  private readonly currentFilterSet = new BehaviorSubject<FilterSet>(this.defaultFilterSet);
  private readonly currentFiltersData = new BehaviorSubject<Filter[]>([...this.filtersDataSnapshot]);
  private readonly budgetFiltersInit = new Subject<void>();
  private readonly filtersCleared = new Subject<void>();
  private readonly externalFiltersSet = new Subject<boolean>();
  private readonly displayState = new BehaviorSubject<boolean>(false);
  public  readonly handleDisplayGoBack = new BehaviorSubject<boolean>(false);
  private readonly customFilterModeChanged = new Subject<CustomFilterMode>();
  public  readonly isFilterFromAdminPage = new BehaviorSubject<boolean>(false);

  private waitingFavouriteBudgetFilters = false;
  private initFilterSet: FilterSet;
  private currentBudget: Budget;
  private _customFilterMode: CustomFilterMode;

  public budgetFiltersList$ = this.budgetFiltersList.asObservable();
  public currentFilterSet$ = this.currentFilterSet.asObservable();
  public currentFiltersData$ = this.currentFiltersData.asObservable();
  public budgetFiltersInit$ = this.budgetFiltersInit.asObservable();
  public filtersCleared$ = this.filtersCleared.asObservable();
  public externalFiltersSet$ = this.externalFiltersSet.asObservable();
  public displayState$ = this.displayState.asObservable();
  public handleDisplayGoBack$ = this.handleDisplayGoBack.asObservable();
  public customFilterModeChanged$ = this.customFilterModeChanged.asObservable();
  public isFilterFromAdminPage$ = this.isFilterFromAdminPage.asObservable();


  private readonly customFieldFiltersManagementService = inject(CustomFieldFiltersManagementService)
  expenseCustomFieldList: any[] = [];
  managePageCustomFieldList: any[] = [];

  public static hasSelectedFilters(filters: FilterSet): boolean {
    return Object.values(filters).some(values => values.length);
  }

  public readonly filterSetStorage = {
    save: (userId: number, budgetId: number, filterSet: FilterSet) => {
      const key = this.filterSetStorage.getStorageKey(userId, budgetId);
      localStorage.setItem(key, JSON.stringify(filterSet));
    },
    get: (userId: number, budgetId: number) => {
      const key = this.filterSetStorage.getStorageKey(userId, budgetId);
      const value = localStorage.getItem(key);
      return value != null ? JSON.parse(value) : null;
    },
    remove: (userId: number, budgetId: number) => {
      if (this.filterSetStorage.get(userId, budgetId) != null) {
        const key = this.filterSetStorage.getStorageKey(userId, budgetId);
        localStorage.removeItem(key);
      }
    },
    getStorageKey: (userId: number, budgetId: number) =>
      this.filterSetLSKey
        .replace(this.userIdPlaceholder, userId.toString())
        .replace(this.budgetIdPlaceholder, budgetId.toString())
  };

  public get currentFilterSetValue(): FilterSet {
    return this.currentFilterSet.value;
  }

  public get customFilterMode(): CustomFilterMode {
    return this._customFilterMode;
  }

  public set customFilterMode(mode: CustomFilterMode) {
    this._customFilterMode = mode;
    this.customFilterModeChanged.next(mode);
    if (!mode) {
      this.turnOffCustomFilterMode();
    }
  }

  constructor(
    private readonly filterDataService: FilterService,
    private readonly budgetDataService: BudgetDataService,
    private readonly companyDataService: CompanyDataService,
    private readonly configuration: Configuration,
    private readonly utilityService: UtilityService,
    private readonly userManager: UserManager,
    private readonly objectAccessManager: ObjectAccessManagerService,
  ) {
    this.prepareFiltersData();

    this.budgetDataService.selectedBudget$
      .pipe(
        tap(budget => {
          if (this.currentBudget != null && (budget == null || this.currentBudget.id !== budget.id)) {
            this.waitingFavouriteBudgetFilters = true;
          }
          this.currentBudget = budget;
          if (this.currentBudget) {
            this.companyDataService.updateUsersListForBudget(this.currentBudget.id);
            this.budgetDataService.loadPoNumbers(this.currentBudget.id, error => this.utilityService.handleError(error));
          }
        }),
        switchMap(budget =>
          this.budgetFiltersList$.pipe(
            first(),
            map(filterList => ({budgetId: budget.id, filterList: filterList}))
          )
        ),
        takeUntil(this.destroy$)
      )
      .subscribe(budgetFiltersListData =>
          setTimeout(() => this.onFilterListInit(budgetFiltersListData))
      );

      this.userManager.afterLoggedIn$
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => this.waitingFavouriteBudgetFilters = true);

      
      this.customFieldFiltersManagementService.expenseCF$.subscribe(cf => {
        this.expenseCustomFieldList = cf; 
      }); 

      this.customFieldFiltersManagementService.managePageCF$.subscribe(cf => {
        this.managePageCustomFieldList = cf; 
      }); 

  }

  toggleFilterVisibility(value: boolean) {
    return this.displayState.next(value);
  }

  setDisplayGoBack(value: boolean) {
    this.handleDisplayGoBack.next(value);
  }

  setFilterFromAdminPage(value: boolean) {
    this.isFilterFromAdminPage.next(value);
  }

  displayFiltersBasedOnAdminPage() {
    return this.isFilterFromAdminPage.value;
  }

  displayAndRetainGoBackToastr() {
    return this.handleDisplayGoBack.value; // return the current state of GoBack Toastr
  }

  private onFilterListInit({ budgetId, filterList }) {
    this.userManager.currentUserId$
      .subscribe(userId => {
        this.budgetFiltersInit.next();
        const favouriteFilters = filterList && filterList.find(filterData => filterData.isFavourite);

        if (!this.applyInitFilterSet()) {
          if (this.waitingFavouriteBudgetFilters && favouriteFilters) {
            if(!this.displayFiltersBasedOnAdminPage()) {
              this.selectActiveFilterSet(favouriteFilters.id, favouriteFilters.selectedFilters);
            }
          } else {
            const savedFilters = this.filterSetStorage.get(userId, budgetId);
            this.currentFilterSet.next(savedFilters || this.defaultFilterSet);
          }
        }
        this.waitingFavouriteBudgetFilters = false;
      });
  }

  private prepareFiltersData() {
    const filtersDataSettings: { filterName: FilterName; sourceList: Observable<{ id: number, name: string }[]> }[] = [
      { filterName: FilterName.Timeframes, sourceList: this.budgetDataService.timeframeList$ },
      { filterName: FilterName.Goals, sourceList: this.budgetDataService.goalList$ },
      { filterName: FilterName.CampaignTypes, sourceList: this.companyDataService.campaignTypesList$ },
      { filterName: FilterName.ExpenseTypes, sourceList: this.companyDataService.expenseTypeList$ },
      { filterName: FilterName.Owners, sourceList: this.companyDataService.companyUsersList$ },
      { filterName: FilterName.GlCodes, sourceList: this.companyDataService.glCodeList$ },
      { filterName: FilterName.Vendors, sourceList: this.companyDataService.vendorList$ },
      { filterName: FilterName.Tags, sourceList: this.companyDataService.tagList$ },
    ];

    combineLatest([
      this.budgetDataService.segmentList$,
      this.budgetDataService.sharedCostRuleList$,
      merge(this.budgetDataService.campaignList$, this.budgetDataService.lightCampaignList$),
      merge(this.budgetDataService.programList$, this.budgetDataService.lightProgramList$),
      this.budgetDataService.segmentGroupList$,
      this.companyDataService.productsAndMetrics$,
      this.budgetDataService.poNumbers$
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([ segments, rules, campaigns = [], programs = [], segmentsGroups = [], metricsData, poNumbers = [] ]) => {
        const filterObjects = <T extends LightObject>(objList: T[]) => {
          return objList.filter((obj) =>
            this.objectAccessManager.hasAccessBySegmentData(
              {
                split_rule: obj.splitRuleId,
                company_budget_segment1: obj.budgetSegmentId
              },
              segments,
              rules
            )
          )
        };

        const filteredRules = this.objectAccessManager.getAllowedSharedCostRules(rules, segments);
        const filteredCampaigns = filterObjects(campaigns);
        const filteredPrograms = filterObjects(programs);

        this.updateSegmentsAvailableValues(segments, segmentsGroups);
        this.updateFiltersData(FilterName.SharedCostRules, filteredRules);
        this.updateCampaignsAvailableValues(filteredCampaigns as Campaign[]);
        this.updateFiltersData(FilterName.ExpenseBuckets, filteredPrograms);
        this.updateExpenseSourceAvailableValues();
        this.updateMetricAvailableValues(metricsData[0], metricsData[1]);
        this.updatePONumberAvailableValues(poNumbers);
        this.updateFiltersData(
          FilterName.Statuses,
          this.configuration.filter_status.map(s => ({ id: s.itemName, name: s.itemName }))
        );
        this.setCEGStatusesAvailableValues();
      });

    filtersDataSettings.forEach(
      item => item.sourceList
        .pipe(takeUntil(this.destroy$))
        .subscribe(objList => this.updateFiltersData(item.filterName, objList))
    );
  }

  private updateSegmentsAvailableValues(segments: BudgetSegmentAccess[], segmentsGroups: SegmentGroup[] = []) {
    const nonGrouped = [];
    const segmentsByGroupId = {};
    const filter = this.filtersDataSnapshot.find(f => f.fieldName === FilterName.Segments);

    segments.forEach(segment => {
      const selectItem = { title: segment.name, value: segment.id };
      if (!segment.segment_group) {
        nonGrouped.push(selectItem)
      } else {
        segmentsByGroupId[segment.segment_group]
          ? segmentsByGroupId[segment.segment_group].push(selectItem)
          : segmentsByGroupId[segment.segment_group] = [ selectItem ];
      }
    })

    filter.availableItems = [
      ...nonGrouped,
      ...segmentsGroups.reduce((items, group) => {
        const filterItem = {
          value: group.id,
          title: group.name,
          children: segmentsByGroupId[group.id] || [],
        };
        return [...items,  filterItem]
      }, [])
    ]
    this.currentFiltersData.next([...this.filtersDataSnapshot]);
  }

  private updateCampaignsAvailableValues(campaigns: Campaign[]) {
    const filter = this.filtersDataSnapshot.find(f => f.fieldName === FilterName.Campaigns);
    const campaignsGroups = this.getCampaignsGroups(campaigns);
    const standAloneCampaigns = [];
    const groupsOfCampaigns = [];
    const allowNotSpecified = this.allowNotSpecifiedOption.includes(FilterName.Campaigns);

    campaigns.forEach(campaign => {
      if (campaignsGroups[campaign.id]) {
        groupsOfCampaigns.push(
          {
            value: campaign.id,
            title: campaign.name,
            children: campaignsGroups[campaign.id].map(camp => ({
              title: camp.name,
              value: camp.id,
            })),
          }
        )
      } else if (!campaign.parentCampaign) {
        standAloneCampaigns.push(
          { title: campaign.name, value: campaign.id }
        )
      }
    })

    filter.availableItems = [
      ...standAloneCampaigns,
      ...groupsOfCampaigns
    ]
    if (allowNotSpecified) {
      filter.availableItems.push({ ...this.notSpecifiedOption, title: `No ${filter.title}` });
    }
    this.currentFiltersData.next([ ...this.filtersDataSnapshot ]);
  }

  private getCampaignsGroups(campaigns: Campaign[]) {
    return campaigns.reduce((grouped, campaign) => {
      if (campaign.parentCampaign) {
        const childCampaigns = grouped[campaign.parentCampaign];
        childCampaigns
          ? childCampaigns.push(campaign)
          : grouped[campaign.parentCampaign] = [ campaign ];
      }
      return grouped;
    }, {})
  }

  private updateFiltersData(filterName: FilterName, availableValues: FilterSourceObject[]) {
    const filter = this.filtersDataSnapshot.find(f => f.fieldName === filterName);
    const allowNotSpecified = this.allowNotSpecifiedOption.includes(filterName) || filter?.type === FilterType.CustomFieldFilter;
    if (filter) {
      if (availableValues != null && availableValues.length > 0) {
        filter.availableItems = availableValues.map(el => {
          const obj = this.objectToFilterSelectItem(el, filter.fieldName);
          if (filter.fieldName === FilterName.ExpenseTypes || filter.fieldName === FilterName.GlCodes || filter.fieldName === FilterName.CampaignTypes) {
            obj['isEnabled'] = el.isEnabled;
            obj['title'] = el.isEnabled ? obj['title'] : `${obj['title']} (Disabled)`
          }
          if (filter.fieldName === FilterName.Vendors) {
            obj['isEnabled'] = el.is_enabled;
            obj['title'] = el.is_enabled ? obj['title'] : `${obj['title']} (Disabled)`
          }
          return obj;
        });
        if (allowNotSpecified) {
          filter.availableItems.push({ ...this.notSpecifiedOption, title: `No ${filter.title}`});
        }
        this.currentFiltersData.next([...this.filtersDataSnapshot]);
      } else if (filter.availableItems.length > 0) {
        filter.availableItems = [];
        this.currentFiltersData.next([...this.filtersDataSnapshot]);
      }
      if (filterName === FilterName.Statuses && this.currentBudget?.new_campaigns_programs_structure) {
        filter.availableItems = [];
      }
      this.correctCurrentFilterSet(filter.fieldName, availableValues);
    }
  }

  private objectToFilterSelectItem(sourceObj: FilterSourceObject, fieldName: string): SelectItem {
    return {
      title: this.getFilterSelectItemTitle(fieldName, sourceObj),
      value: sourceObj.id
    };
  }

  private getFilterSelectItemTitle(fieldName, item) {
    const titleGettersByFieldName = {
      'glCodes': (el): string => `${el.name} | ${el.description}`
    };

    return titleGettersByFieldName[fieldName]
      ? titleGettersByFieldName[fieldName](item)
      : item.name;
  }

  private correctCurrentFilterSet(fieldName: string, availableValues: FilterSourceObject[]) {
    const filterSet = this.currentFilterSet.value;
    if (filterSet && filterSet[fieldName]) {
      const prevSelectedLength = filterSet[fieldName].length;
      filterSet[fieldName] =
        filterSet[fieldName].filter(
          value => value === this.notSpecifiedOption.value ||
            availableValues && availableValues.map(av => av.id).includes(value)
        );
      const madeCorrection = prevSelectedLength !== filterSet[fieldName].length;
      madeCorrection && this.currentFilterSet.next(filterSet);
    }
  }

  private getValuesMatchingAvailable(fieldName: string, sourceValues: SelectedValue[]) {
    const filterData = this.filtersDataSnapshot.find(data => data.fieldName === fieldName);
    return filterData && filterData.availableItems && filterData.availableItems.length ?
      sourceValues.filter(value => filterData.availableItems.some(availItem => availItem.value === value)) :
      sourceValues;
  }

  public isKnownFilterKey(keyName: string): boolean {
    return this.filtersDataSnapshot.some(data => data.fieldName === keyName);
  }

  public loadSavedFilters(budgetId: number, onErrorCb?: (error) => void) {
    this.filterDataService.getFilters({
      budget: budgetId
    }).pipe(map((responseData: any[]) => responseData?.map(this.adaptFilterSet)))
      .subscribe(
        data => this.budgetFiltersList.next(data),
        error => onErrorCb && onErrorCb(error)
    )
  }

  public get activeFilterSetId() {
    return Number(localStorage.getItem(this.activeFilterSetLSKey));
  }

  public updateCurrentFilterSet(filterSet?: FilterSet, callBack?, clearActiveIdStorage = true) {
    if(clearActiveIdStorage) {
      localStorage.setItem(this.activeFilterSetLSKey, null);
    }
    this.currentFilterSet.next(filterSet || this.defaultFilterSet);
    callBack?.();
  }

  public setParamsFromFilters(
    targetParamsObj: any,
    paramsDef: ParamsDef,
    convertArrayToString = false,
    currentFilters: FilterSet = this.currentFilterSet.value,
    isManagePageFilters = false
    ) {
    if (!targetParamsObj || !paramsDef) {
      return;
    }
    Object.keys(paramsDef).forEach(paramName => {
      const paramDef = paramsDef[paramName];
      if (paramDef) {
        const paramValue =
          !currentFilters || !paramDef.filterName ?
            paramDef.defaultValue?.() :
            currentFilters[paramDef.filterName]?.length ?
              currentFilters[paramDef.filterName] :
              paramDef.defaultValue && paramDef.defaultValue();
        if (paramValue && (paramValue.length == null || paramValue.length > 0)) {
          targetParamsObj[paramName] =
            Array.isArray(paramValue) && convertArrayToString ?
              paramValue.join(',') :
              paramValue;
        }
      }
    });
      
    // Add Custom Fields Filter If present to Target Params 
      let selectedCFFiltersQuery = this.getCurrentSelectedCustomFieldFilterSet(isManagePageFilters);
      if(Object.keys(selectedCFFiltersQuery).length > 0) {
          targetParamsObj['custom_fields'] = this.createCustomFieldFilterPayload(selectedCFFiltersQuery, isManagePageFilters);      
      }
    
  }

  public getCurrentSelectedCustomFieldFilterSet(isManagePageFilters = false) {
    const currentFilters = this.currentFilterSet.value
    const expenseCFFilters = this.customFieldFiltersManagementService.getExpenseCFValue().map(cf => ({ name: cf.cfName, id: cf.id}) );
    const managePageCFFilters = this.customFieldFiltersManagementService.getManagePageCFValue().map(cf => ({ name: cf.cfName, id: cf.id}) );

    let customFieldFilters = isManagePageFilters ? managePageCFFilters : expenseCFFilters;

      let customFieldsFilterQuery = {}
  
      if(customFieldFilters.length > 0) {    
        customFieldFilters.forEach(filter => {
            if(filter.name in currentFilters && currentFilters[filter.name].length > 0) {
            customFieldsFilterQuery = {...customFieldsFilterQuery, [filter.id]:  currentFilters[filter.name] }
          }
        })
        
      }  
      return customFieldsFilterQuery;
  }

  public createCustomFieldFilterPayload(selectedCFFiltersQuery: object, isManagePageFilters = false) {
    
    let customFieldFiltersPayload = {}

    let customFieldList = isManagePageFilters ? this.managePageCustomFieldList : this.expenseCustomFieldList;

    Object.keys(selectedCFFiltersQuery).forEach(cfId => {
      let currentCustomField =  customFieldList.find(cf => Number(cf.id) === Number(cfId));
      let allCFOptions =  Object.values(currentCustomField?.optionValueIdMapping || []).map((obj: { id: number, option_value: string }) => obj.id) || [];
      let selectedCustomFiltersOptionIds = selectedCFFiltersQuery[cfId].filter(opt => opt !== this.notSpecifiedOption.value) 

      let selectedOptionsIds = this.customFieldFiltersManagementService.allOptionsSelectedForCFFilter(allCFOptions, selectedCustomFiltersOptionIds) ? -1 : selectedCustomFiltersOptionIds;
      let isNotSpecifiedOptionSelected = selectedCFFiltersQuery[cfId].includes(this.notSpecifiedOption.value);
      
      if(!(selectedOptionsIds === -1 && isNotSpecifiedOptionSelected)) {
          customFieldFiltersPayload[cfId] = {
            opt_ids: selectedOptionsIds,
            opt_not_specified: isNotSpecifiedOptionSelected 
          }
       }

    })

    return customFieldFiltersPayload

  }

  public saveFilterSetData(filterSetData: FilterSetData, budgetId: number, errorCb?: (error) => void) {
    return this.filterDataService.createFilterSet(filterSetData).pipe(
      tap(() => this.loadSavedFilters(budgetId, errorCb))
    );
  }

  public updateFilterSetData(filterSetData: FilterSetData, budgetId: number, errorCb?: (error) => void) {
    if (filterSetData.id === this.activeFilterSetId && !filterSetData.isFavourite) {
      localStorage.setItem(this.activeFilterSetLSKey, null);
    }
    return this.filterDataService.updateFilterSet(filterSetData).pipe(
      tap(() => this.loadSavedFilters(budgetId, errorCb))
    );
  }

  public deleteFilterSetData(id: number, budgetId: number, errorCb?: (error) => void) {
    return this.filterDataService.deleteFilterSet(id).pipe(
      tap(() => this.loadSavedFilters(budgetId, errorCb))
    );
  }

  public selectActiveFilterSet(id: number, filterSet: FilterSet) {
    localStorage.setItem(this.activeFilterSetLSKey, String(id));
    this.currentFilterSet.next(filterSet || this.defaultFilterSet);
  }

  public selectFavouriteStatus(id: number, prevId: number, budgetId: number,  errorCb?: (error) => void) {
    const requests = [
      this.filterDataService.updateFilterSetField(id, 'is_favourite', true)
    ];
    if (prevId) {
      requests.push(this.filterDataService.updateFilterSetField(prevId, 'is_favourite', false))
    }
    return forkJoin(requests).pipe(
      tap(() => this.loadSavedFilters(budgetId, errorCb))
    )
  }

  public resetFavouriteStatus(id: number, budgetId: number,  errorCb?: (error) => void) {
    return this.filterDataService.updateFilterSetField(id, 'is_favourite', false).pipe(
      tap(() => this.loadSavedFilters(budgetId, errorCb))
    )
  }

  public getDefaultSegments(allSegments: BudgetSegmentAccess[]): number[] {
    const allSegmentsAvailable = this.budgetDataService.allSegmentsAvailable;
    const companyUser = this.userManager.getCurrentCompanyUser();
    const isRegularUser = !companyUser.is_admin && !companyUser.is_account_owner;
    return isRegularUser && !allSegmentsAvailable && allSegments != null && allSegments.length > 0 ?
      allSegments.map(seg => seg.id) :
      null;
  }

  public getFilterCampaignIds(allCampaigns: LightCampaign[]): number[] {
    const filterCampaignIds = this.currentFilterSet.value?.[FilterName.Campaigns];
    return filterCampaignIds?.length ?
      allCampaigns
        .filter(campaign => filterCampaignIds.includes(campaign.id))
        .reduce(
          (campaignIds, currentCampaign) => [
            ...campaignIds,
            currentCampaign.id,
            ...allCampaigns.filter(campaign => campaign.parentCampaign === currentCampaign.id).map(campaign => campaign.id)
          ],
          []
        ) :
      null;
  }

  private adaptFilterSet(rawFilterSetData): FilterSetData {
    return {
      id: rawFilterSetData.id,
      name: rawFilterSetData.name,
      budgetId: rawFilterSetData.budget,
      isFavourite: rawFilterSetData.is_favourite,
      ownerId: rawFilterSetData.owner,
      isPublic: rawFilterSetData.is_public,
      selectedFilters: rawFilterSetData.filter_data
    }
  }

  initWithFilterSet(filterSet: FilterSet) {
    this.initFilterSet = filterSet;
  }

  applyInitFilterSet(): boolean {
    if (!this.initFilterSet) {
      return false;
    }

    const filterSet =
      Object.keys(this.initFilterSet)
        .reduce((filters, key) => {
          const values = this.getValuesMatchingAvailable(key, this.initFilterSet[key]);
          return values && values.length ?
            {...filters, [key]: values} :
            filters;
        }, {});

      this.currentFilterSet.next(filterSet);
      this.initFilterSet = null;
      return true;
  }

  notifyFiltersCleared() {
    this.filtersCleared.next();
  }

  setExternalFilters(extFiltersOn: boolean) {
    this.externalFiltersSet.next(extFiltersOn);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private updateExpenseSourceAvailableValues(): void {
    const filter = this.filtersDataSnapshot.find(f => f.fieldName === FilterName.ExpenseSource);
    filter.availableItems = expenseSources.map(expenseSourceId => {
      return { title: BudgetObjectSourceLabels[expenseSourceId], value: expenseSourceId }
    });
  }

  private updateMetricAvailableValues(products: ProductDO[], metrics): void {
    const filter = this.filtersDataSnapshot.find(f => f.fieldName === FilterName.Metrics);
    filter.availableItems = getMetricSelectItems(products, metrics, true);
  }

  private turnOffCustomFilterMode() {
    const currentFilters = this.currentFilterSet.value;
    this.currentFilterSet.next(currentFilters ? {...currentFilters} : null);
  }

  private updatePONumberAvailableValues(poNumbers: string[]): void {
    const filter = this.filtersDataSnapshot.find(f => f.fieldName === FilterName.PONumber);
    const allowNotSpecified = this.allowNotSpecifiedOption.includes(FilterName.PONumber);
    if (this.currentBudget?.new_campaigns_programs_structure) {
      filter.availableItems = this.preparePoNumbers(poNumbers);
      if (allowNotSpecified) {
        filter.availableItems.push({ ...this.notSpecifiedOption, title: `No ${filter.title}` });
      }
    } else {
      filter.availableItems = [];
    }
  }

  private preparePoNumbers(poNumbers: string[]): SelectItem[] {
    return (poNumbers || []).map(item => ({title: item, value: item}));
  }

  private setCEGStatusesAvailableValues(): void {
    const filter = this.filtersDataSnapshot.find(f => f.fieldName === FilterName.CEGStatus);
    if (this.currentBudget?.new_campaigns_programs_structure) {
      filter.availableItems = [
        { title: CEGStatus.PLANNED, value: CEGStatusValue[CEGStatus.PLANNED] },
        { title: CEGStatus.COMMITTED, value: CEGStatusValue[CEGStatus.COMMITTED] }
      ];
    } else {
      filter.availableItems = [];
    }
  }
}
